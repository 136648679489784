.mentions {
  width: 100%;
  margin: 1em 0;
}

.mentions--multiLine .mentions__control {
  min-height: 5rem;
  max-height: 10rem;
}

.mentions--multiLine .mentions__input {
  overflow-y: auto !important;
}

.mentions--multiLine .mentions__highlighter {
  min-height: 5rem;
  max-height: 10rem;
  padding: 9px;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--singleLine .mentions__textarea {
  background-color: red;
}

.mentions--multiLine .mentions__input {
  border: 1px solid hsl(var(--border));
  padding: 9px;
  border-radius: var(--radius);
  outline: 0;
  overflow: auto;
  height: 70;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  border-radius: var(--radius);
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: hsl(var(--muted));
}

.mentions__mention {
  position: relative;
  width: 100%;
  z-index: 1;
  color: white;
  background-color: hsl(var(--primary));
  text-decoration: underline;
  pointer-events: none;
}
